import React from 'react';
import { motion } from 'framer-motion';

const TeamSection = ({ setCurrentPage }) => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <section className="min-h-screen relative py-8 md:py-16 lg:py-20 xl:py-24 overflow-hidden">
      {/* Background with animated overlay */}
      <motion.div 
        className="absolute inset-0"
        style={{
          backgroundImage: "url('/assets/jb2.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: "overlay",
        }}
        animate={{
          backgroundColor: ['rgba(0, 0, 0, 0.6)', 'rgba(0, 0, 0, 0.7)'],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
          repeatType: "reverse"
        }}
      />

      <div className="relative px-4 py-8 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="text-center">
          <motion.div
            initial="initial"
            animate="animate"
            variants={fadeIn}
          >
            <h2 className="text-2xl font-normal text-white sm:text-4xl lg:text-5xl xl:text-6xl mt-8 md:mt-0">
            Innovation Meets Excellence
            </h2>
            <motion.p 
              className="max-w-md mx-auto mt-6 text-lg font-normal text-gray-400"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              At Jayco Links, we are a team of passionate innovators, dedicated to crafting cutting-edge tech solutions that redefine possibilities.
            </motion.p>
          </motion.div>

          <motion.div 
            className="relative inline-flex items-center justify-center mt-8 sm:mt-12 group"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50">
            </div>
            <button 
              onClick={() => setCurrentPage("Career")}
              className="relative inline-flex items-center justify-center px-8 py-3 text-base font-normal text-white bg-black border border-transparent rounded-full"
              role="button"
            >
              See Career Opportunities
            </button>
          </motion.div>
        </div>

        {/* <motion.div 
          className="grid items-start grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7 }}
        >
          <div className="relative group">
            <img className="w-full transition-all duration-200 filter grayscale group-hover:grayscale-0 bg-blend-multiply"
              src="https://landingfoliocom.imgix.net/store/collection/dusk/images/team/2/team-member-1.png" alt="" />

            <div className="absolute bottom-0 left-0">
              <svg className="w-[3px] h-60 md:h-80 lg:h-96 xl:h-auto" viewBox="0 0 3 453" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <line x1="1.5" y1="-6.55671e-08" x2="1.50002" y2="453" stroke="url(#paint0_linear_534_2132)"
                  strokeWidth="3" />
                <defs>
                  <linearGradient id="paint0_linear_534_2132" x1="0" y1="0" x2="-7.90673" y2="0.120584"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6DDCFF" stopOpacity="0" />
                    <stop offset="1" stopColor="#7F60F9" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <div className="absolute inset-x-0 bottom-0 origin-bottom-left transform scale-50 whitespace-nowrap sm:scale-75 lg:scale-100">
              <div className="p-4 lg:py-8 lg:px-6">
                <p className="text-xl font-semibold text-gray-400 transition-all duration-200 group-hover:text-white">
                  Cameron Williamson
                </p>
                <p className="text-base mt-0.5 font-normal text-gray-400 transition-all duration-200 group-hover:text-white">
                  Co-Founder, CEO
                </p>
              </div>
            </div>
          </div>

          <div className="relative mt-8 sm:mt-0 group">
            <img className="w-full transition-all duration-200 filter grayscale group-hover:grayscale-0 bg-blend-multiply"
              src="https://landingfoliocom.imgix.net/store/collection/dusk/images/team/2/team-member-2.png" alt="" />

            <div className="absolute bottom-0 left-0">
              <svg className="w-[3px] h-60 md:h-80 lg:h-96 xl:h-auto" viewBox="0 0 3 453" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <line x1="1.5" y1="-6.55671e-08" x2="1.50002" y2="453" stroke="url(#paint0_linear_534_2132)"
                  strokeWidth="3" />
                <defs>
                  <linearGradient id="paint0_linear_534_2132" x1="0" y1="0" x2="-7.90673" y2="0.120584"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6DDCFF" stopOpacity="0" />
                    <stop offset="1" stopColor="#7F60F9" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <div className="absolute bottom-0 right-0">
              <svg className="w-[3px] h-60 md:h-80 lg:h-96 xl:h-auto" viewBox="0 0 3 453" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <line x1="1.5" y1="-6.55671e-08" x2="1.50002" y2="453" stroke="url(#paint0_linear_534_2132)"
                  strokeWidth="3" />
                <defs>
                  <linearGradient id="paint0_linear_534_2132" x1="0" y1="0" x2="-7.90673" y2="0.120584"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6DDCFF" stopOpacity="0" />
                    <stop offset="1" stopColor="#7F60F9" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <div className="absolute inset-x-0 bottom-0 origin-bottom-left transform scale-50 whitespace-nowrap sm:scale-75 lg:scale-100">
              <div className="p-4 lg:py-8 lg:px-6">
                <p className="text-xl font-semibold text-gray-400 transition-all duration-200 group-hover:text-white">
                  Ralph Edwards
                </p>
                <p className="text-base mt-0.5 font-normal text-gray-400 transition-all duration-200 group-hover:text-white">
                  Software Engineer
                </p>
              </div>
            </div>
          </div>

          <div className="relative group">
            <img className="w-full transition-all duration-200 filter grayscale group-hover:grayscale-0 bg-blend-multiply"
              src="https://landingfoliocom.imgix.net/store/collection/dusk/images/team/2/team-member-3.png" alt="" />

            <div className="absolute inset-x-0 bottom-0 origin-bottom-left transform scale-50 whitespace-nowrap sm:scale-75 lg:scale-100">
              <div className="p-4 lg:py-8 lg:px-6">
                <p className="text-xl font-semibold text-gray-400 transition-all duration-200 group-hover:text-white">
                  Robert Fox
                </p>
                <p className="text-base mt-0.5 font-normal text-gray-400 transition-all duration-200 group-hover:text-white">
                  Project Manager
                </p>
              </div>
            </div>
          </div>

          <div className="relative mt-12 sm:mt-0 group">
            <img className="w-full transition-all duration-200 filter grayscale group-hover:grayscale-0 bg-blend-multiply"
              src="https://landingfoliocom.imgix.net/store/collection/dusk/images/team/2/team-member-4.png" alt="" />

            <div className="absolute bottom-0 left-0">
              <svg className="w-[3px] h-60 md:h-80 lg:h-96 xl:h-auto" viewBox="0 0 3 453" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <line x1="1.5" y1="-6.55671e-08" x2="1.50002" y2="453" stroke="url(#paint0_linear_534_2132)"
                  strokeWidth="3" />
                <defs>
                  <linearGradient id="paint0_linear_534_2132" x1="0" y1="0" x2="-7.90673" y2="0.120584"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6DDCFF" stopOpacity="0" />
                    <stop offset="1" stopColor="#7F60F9" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <div className="absolute bottom-0 right-0">
              <svg className="w-[3px] h-60 md:h-80 lg:h-96 xl:h-auto" viewBox="0 0 3 453" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <line x1="1.5" y1="-6.55671e-08" x2="1.50002" y2="453" stroke="url(#paint0_linear_534_2132)"
                  strokeWidth="3" />
                <defs>
                  <linearGradient id="paint0_linear_534_2132" x1="0" y1="0" x2="-7.90673" y2="0.120584"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6DDCFF" stopOpacity="0" />
                    <stop offset="1" stopColor="#7F60F9" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <div className="absolute inset-x-0 bottom-0 origin-bottom-left transform scale-50 whitespace-nowrap sm:scale-75 lg:scale-100">
              <div className="p-4 lg:py-8 lg:px-6">
                <p className="text-xl font-semibold text-gray-400 transition-all duration-200 group-hover:text-white">
                  Dianne Russell
                </p>
                <p className="text-base mt-0.5 font-normal text-gray-400 transition-all duration-200 group-hover:text-white">
                  Team Leader
                </p>
              </div>
            </div>
          </div>
        </motion.div> */}
      </div>
    </section>
  );
};

export default TeamSection;