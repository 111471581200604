import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

const Portfolio = ({ setCurrentPage }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const investments = [
    {
      title: "Rendezvous Luxury Care Services",
      description: "Delivering a seamless blend of luxury, wellness, and connection through premium self-care essentials, professional therapy, life coaching, and bespoke matchmaking experiences.",
      image: "/assets/Rendezvous.png",
      color: "from-purple-500/20 to-pink-500/20",
      path: "https://www.rendezvouscare.com/" 
    },
    {
      title: "Payrendr",
      description: "Simplifying payroll and HR management with cutting-edge financial solutions. Payrendr empowers businesses with seamless employee payments, tax compliance, and automated salary processing.",
      image: "/assets/Payrendr.png",
      color: "from-blue-500/20 to-cyan-500/20",
      path: "/payrendr"
    },
    {
      title: "Ndonu",
      description: "Bridging the gap between skilled artisans and clients in need of reliable services. Ndonu connects users with verified professionals while providing a marketplace for tools and resources.",
      image: "/assets/Ndonu.png",
      color: "from-gray-700/20 to-gray-500/20",
      path: "/ndonu"
    },
    {
      title: "VTB Logistics",
      description: "Revolutionizing logistics with end-to-end overhauling services and a dynamic marketplace that connects drivers with clients. VTB ensures seamless transport solutions for businesses and individuals alike.",
      image: "/assets/VanTruckBus.png",
      color: "from-orange-500/20 to-yellow-500/20",
      path: "https://vantruckbus.com/"
    },
    {
      title: "Jayco Estates",
      description: "Building the future of real estate through sustainable, modern, and high-value property investments. Horizon Estates delivers premier housing, commercial developments, and real estate solutions tailored to modern lifestyles.",
      image: "/assets/real-estate.png",
      color: "from-red-500/20 to-rose-500/20",
      path: "/estates"
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  const handleProjectClick = (path) => {
    if (path.startsWith("http")) {
      window.open(path, "_blank", "noopener,noreferrer"); // Open external links in new tab
    } else {
      setCurrentPage(path); // Internal navigation
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white px-8 py-12 overflow-hidden" 
      style={{
        backgroundImage: "url('/assets/jb2.jpg')",
        backgroundBlendMode: "overlay",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
      }}>
      
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-center mb-16 relative z-10"
      >
        <h1 className="text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300">
          Our Portfolio
        </h1>
        <p className="text-xl text-gray-300 max-w-2xl mx-auto leading-relaxed">
          At Jayco Links, we are proud to invest in diverse industries to create impactful solutions.
        </p>
      </motion.div>

      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 relative z-10 max-w-7xl mx-auto"
      >
        {investments.map((investment, index) => (
          <motion.div
            key={index}
            variants={itemVariants}
            onHoverStart={() => setHoveredIndex(index)}
            onHoverEnd={() => setHoveredIndex(null)}
            className="group relative overflow-hidden rounded-xl"
          >
            <motion.div 
              className={`absolute inset-0 bg-gradient-to-br ${investment.color} opacity-0 group-hover:opacity-100 transition-opacity duration-500`}
              initial={false}
              animate={{
                scale: hoveredIndex === index ? 1.1 : 1,
              }}
              transition={{ duration: 0.3 }}
            />
            
            <div className="relative backdrop-blur-lg bg-white/10 p-6 rounded-xl border border-white/20
                          group-hover:bg-white/20 group-hover:border-white/30 
                          transition-all duration-500 h-full">
              <motion.div
                initial={false}
                animate={{
                  scale: hoveredIndex === index ? 1.02 : 1,
                }}
                transition={{ duration: 0.3 }}
              >
                <div className="relative overflow-hidden rounded-lg mb-6">
                  <img
                    src={investment.image}
                    alt={investment.title}
                    className="w-full h-48 object-cover transform group-hover:scale-110 transition-transform duration-700"
                  />
                  <div className="absolute inset-0 bg-black/20 group-hover:bg-black/0 transition-colors duration-300" />
                </div>
                
                <button 
                  onClick={() => handleProjectClick(investment.path)}
                  className="w-full text-left group/title"
                >
                  <h2 className="text-2xl font-semibold mb-3 group-hover:text-white transition-colors duration-300 flex items-center gap-2">
                    {investment.title}
                    <ArrowRight className="w-5 h-5 opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform group-hover/title:translate-x-1" />
                  </h2>
                </button>
                
                <p className="text-gray-300 group-hover:text-white/90 transition-colors duration-300">
                  {investment.description}
                </p>
              </motion.div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default Portfolio;