import React from "react";
import { Link } from "react-router-dom";

const About = ({ setCurrentPage }) => {
  return (
    <div className="h-screen w-full bg-gray-900 text-gray-200 flex flex-col">
      {/* Main Content */}
      <div className="flex-grow grid grid-rows-2 lg:grid-cols-2 lg:grid-rows-1">
        {/* Left Column: Company Introduction */}
        <div
          className="flex flex-col justify-center items-center text-center lg:text-left px-6 sm:px-12 lg:px-16 bg-cover bg-center"
          style={{
            backgroundImage: "url('/assets/jb2.jpg')",
            backgroundBlendMode: "overlay",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          <div className="bg-black bg-opacity-50 p-6 rounded-lg">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white">
              About Jayco Links Investments
            </h1>
            <p className="text-lg sm:text-xl text-gray-300 mt-4">
              Driving change through strategic investments.
            </p>
            <p className="mt-6 text-sm sm:text-base lg:text-lg leading-relaxed text-gray-300">
              At Jayco Links Investments, we are committed to empowering
              businesses and individuals to achieve their full potential. Our
              expertise spans multiple industries, enabling us to craft
              innovative solutions tailored for long-term success. With a focus
              on sustainability, collaboration, and excellence, we aim to shape
              a brighter future.
            </p>
            {/* Portfolio Link */}
            <button
              onClick={() => setCurrentPage("Portfolio")}
              className="inline-block mt-6 text-sm sm:text-base lg:text-lg font-semibold text-indigo-400 hover:text-indigo-300 relative group"
            >
              See Our Outstanding Projects
              <span
                className="absolute left-0 bottom-0 w-0 h-0.5 bg-indigo-400 transition-all group-hover:w-full"
              ></span>
            </button>
          </div>
        </div>

        {/* Right Column: Mission, Vision, and Core Values */}
        <div className="flex flex-col justify-center px-6 sm:px-12 lg:px-16 space-y-12">
          {/* Mission and Vision */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-12">
            <div>
              <h3
                className="text-xl sm:text-2xl font-bold"
                style={{ color: "#056CB9" }}
              >
                Our Mission
              </h3>
              <p className="mt-4 text-sm sm:text-base lg:text-lg text-gray-300">
                To empower individuals and organizations with the resources and
                support they need to drive innovation, foster collaboration, and
                achieve sustainable growth.
              </p>
            </div>
            <div>
              <h3
                className="text-xl sm:text-2xl font-bold"
                style={{ color: "#056CB9" }}
              >
                Our Vision
              </h3>
              <p className="mt-4 text-sm sm:text-base lg:text-lg text-gray-300">
                To lead the global investment industry by delivering exceptional
                value, driving transformative solutions, and inspiring a new
                era of progress.
              </p>
            </div>
          </div>

          {/* Core Values */}
          <div>
            <h3
              className="text-xl sm:text-2xl font-bold"
              style={{ color: "#056CB9" }}
            >
              Our Core Values
            </h3>
            <div className="mt-6 grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8">
              {[
                "Integrity",
                "Innovation",
                "Excellence",
                "Collaboration",
                "Sustainability",
              ].map((value) => (
                <div
                  key={value}
                  className="p-4 border border-gray-700 rounded-lg shadow-sm hover:shadow-lg transition text-center"
                >
                  <h4
                    className="text-sm sm:text-base lg:text-lg font-semibold"
                    style={{ color: "#056CB9" }}
                  >
                    {value}
                  </h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;