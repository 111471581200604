import React from "react";

const Footer = () => {
  return (
    <footer className="py-4 bg-transparent-800 text-center">
        <p className="text-sm text-gray-400">
          © {new Date().getFullYear()} Jayco Links Investments. All rights
          reserved.
        </p>
      </footer>
  );
};

export default Footer;