import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";

const Navbar = ({ setCurrentPage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ${
        hasScrolled ? "bg-black-900 shadow-md" : "bg-transparent"
      }`}
    >
      <div className="flex items-center justify-between px-4 sm:px-6 md:px-12 py-6">
        {/* Logo */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setCurrentPage("Home")}
        >
          <img
            src="/assets/logo_icon.png"
            alt="Jayco Links Logo"
            className="h-10 w-10 sm:h-20 sm:w-20 ml-3"
          />
        </div>

        {/* Hamburger Menu Icon */}
        <div
          className="text-white text-6xl cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          ☰
        </div>
      </div>

      {/* Full-Screen Menu with Close Button */}
      <Transition
        as="div"
        show={isOpen}
        enter="transition-opacity duration-300 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center"
      >
        {/* Close Button */}
        <div
          className="absolute top-6 right-6 text-white text-4xl cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          ✕
        </div>

        {/* Menu Links */}
        <ul className="flex flex-col text-center space-y-6">
          <li
            className="text-white text-4xl cursor-pointer hover:text-gray-400 transition-colors"
            onClick={() => {
              setCurrentPage("Home");
              setIsOpen(false);
            }}
          >
            Home
          </li>
          <li
            className="text-white text-4xl cursor-pointer hover:text-gray-400 transition-colors"
            onClick={() => {
              setCurrentPage("About");
              setIsOpen(false);
            }}
          >
            The Company
          </li>
          <li
            className="text-white text-4xl cursor-pointer hover:text-gray-400 transition-colors"
            onClick={() => {
              setCurrentPage("Portfolio");
              setIsOpen(false);
            }}
          >
            Portfolio
          </li>
          <li
            className="text-white text-4xl cursor-pointer hover:text-gray-400 transition-colors"
            onClick={() => {
              setCurrentPage("Career");
              setIsOpen(false);
            }}
          >
            Career
          </li>
          <li
            className="text-white text-4xl cursor-pointer hover:text-gray-400 transition-colors"
            onClick={() => {
              setCurrentPage("Contact");
              setIsOpen(false);
            }}
          >
            Contact
          </li>
          <li
            className="text-white text-4xl cursor-pointer hover:text-gray-400 transition-colors"
            onClick={() => {
              setCurrentPage("Team");
              setIsOpen(false);
            }}
          >
            Team
          </li>
        </ul>
      </Transition>
    </nav>
  );
};

export default Navbar;