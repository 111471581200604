import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";

const Home = ({ setCurrentPage }) => {
  return (
    <div
      className="h-screen w-full bg-cover bg-center flex flex-col justify-between px-4"
      style={{
        backgroundImage: "url('/assets/jb.jpg')",
      }}
    >
      {/* Hero Content */}
      <div className="flex-grow flex items-center justify-center text-center">
        <div>
          <h1 className="text-white text-5xl sm:text-6xl md:text-7xl lg:text-9xl font-extrabold leading-tight">
            Jayco Links Investments
          </h1>
          <p className="text-white text-2xl sm:text-xl md:text-2xl lg:text-4xl mt-4 sm:mt-6">
            Invest in the Future. Join Us Today.
          </p>
          {/* CTA Button */}
          <button
             onClick={() => setCurrentPage("About")}
            className="mt-6 sm:mt-8 px-8 py-4 text-lg sm:text-xl text-white font-semibold rounded-xl shadow-md transition-all"
            style={{
              backgroundColor: "#056CB9",
            }}
          >
            Learn More
          </button>
        </div>
      </div>

      {/* Social Icons */}
      <div className="flex justify-center sm:justify-start sm:fixed sm:top-1/2 sm:left-2 sm:transform sm:-translate-y-1/2 sm:flex-col sm:items-center sm:space-y-8 space-x-4 pb-4 sm:pb-0">
        {/* Vertical Line (Visible on larger screens) */}
        <div className="hidden sm:block sm:absolute sm:top-5 sm:left-20 sm:h-full border-l-2 border-white"></div>

        {/* Social Icons */}
        <a
          href="https://www.facebook.com/share/1Yq4Sv7Fyo/?mibextid=wwXIfr"
          target="_blank"
          rel="noopener noreferrer"
          className="w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full border-2 border-white text-white hover:text-gray-300 transition-all"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://x.com/Rendezvouscare"
          target="_blank"
          rel="noopener noreferrer"
          className="w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full border-2 border-white text-white hover:text-gray-300 transition-all"
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.instagram.com/rendezvouscare?igsh=MWJ5c3A1ZWIycnh3NQ=="
          target="_blank"
          rel="noopener noreferrer"
          className="w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full border-2 border-white text-white hover:text-gray-300 transition-all"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.linkedin.com/company/jayco-links-nig-ltd/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full border-2 border-white text-white hover:text-gray-300 transition-all"
        >
          <FaLinkedinIn />
        </a>
      </div>
    </div>
  );
};

export default Home;