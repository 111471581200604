import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Sparkles, Star, Send } from 'lucide-react';

const Career = ({ setCurrentPage }) => {
    // const [score, setScore] = useState(0);
    const [stars, setStars] = useState([]);
    const controls = useAnimation();

    // Generate random position for floating elements
    const randomPosition = () => ({
        x: Math.random() * (window.innerWidth - 100),
        y: Math.random() * (window.innerHeight - 100)
    });

    // Add star on click
    const addStar = (e) => {
        const newStar = {
            id: Date.now(),
            x: e.clientX,
            y: e.clientY
        };
        setStars(prev => [...prev, newStar]);
        // setScore(prev => prev + 10);
    };

    // Floating animation for background elements
    const floatingAnimation = {
        initial: { y: 0 },
        animate: {
            y: [-10, 10, -10],
            transition: {
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
            }
        }
    };

    return (
        <motion.div 
            className='min-h-screen w-full relative flex flex-col items-center justify-center bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white px-4 sm:px-6 md:px-8 py-8 md:py-12 overflow-hidden cursor-pointer'
            style={{
                backgroundImage: "url('/assets/jb2.jpg')",
                backgroundBlendMode: "overlay",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
            onClick={addStar}
        >
            {/* Floating sparkles */}
            {[...Array(5)].map((_, i) => (
                <motion.div
                    key={i}
                    className="absolute"
                    initial={randomPosition()}
                    animate={floatingAnimation.animate}
                    transition={{ delay: i * 0.2 }}
                >
                    <Sparkles className="text-yellow-300 opacity-50" size={24} />
                </motion.div>
            ))}

            {/* Click-generated stars */}
            {stars.map(star => (
                <motion.div
                    key={star.id}
                    className="absolute"
                    initial={{ x: star.x, y: star.y, scale: 1, opacity: 1 }}
                    animate={{ scale: 0, opacity: 0, y: star.y - 50 }}
                    transition={{ duration: 0.5 }}
                    onAnimationComplete={() => {
                        setStars(prev => prev.filter(s => s.id !== star.id));
                    }}
                >
                    <Star className="text-yellow-400" size={24} />
                </motion.div>
            ))}

            {/* Score display */}
            <motion.div 
                className="fixed top-4 right-4 bg-white/10 backdrop-blur-sm rounded-lg p-2"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                whileHover={{ scale: 1.1 }}
            >
                {/* <p className="text-white font-bold">Score: {score}</p> */}
            </motion.div>

            <motion.div 
                className="max-w-4xl mx-auto text-center relative z-10 space-y-6 md:space-y-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
            >
                <motion.h1 
                    className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 md:mb-8 bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    whileHover={{ scale: 1.05 }}
                    transition={{ delay: 0.2 }}
                >
                    Join Our Team
                </motion.h1>

                <motion.div 
                    className="space-y-4 md:space-y-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                >
                    <motion.p 
                        className="text-lg sm:text-xl leading-relaxed text-gray-200 px-4 sm:px-0"
                        whileHover={{ scale: 1.02 }}
                    >
                        We believe in the power of collaboration and innovation. Our culture is built on trust, 
                        creativity, and the shared pursuit of excellence.
                    </motion.p>
                    
                    <motion.p 
                        className="text-base sm:text-lg text-gray-300 px-4 sm:px-0"
                        whileHover={{ scale: 1.02 }}
                    >
                        Here, every voice matters and every team member has the opportunity to make a real impact. 
                        We foster an environment where ideas flourish and individuals grow.
                    </motion.p>
                </motion.div>

                <motion.div 
                    className="mt-8 md:mt-12"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6 }}
                >
                    <motion.h2 
                        className="text-xl sm:text-2xl font-semibold mb-4"
                        whileHover={{ scale: 1.05 }}
                    >
                        Open Positions
                    </motion.h2>
                    <motion.div 
                        className="p-4 sm:p-6 bg-white/10 backdrop-blur-sm rounded-lg mx-4 sm:mx-0"
                        whileHover={{ scale: 1.02 }}
                    >
                        <p className="text-gray-300 text-sm sm:text-base">
                            Available roles will be posted here. Check back regularly for new opportunities 
                            to join our growing team.
                        </p>
                    </motion.div>
                </motion.div>

                <motion.div 
                    className="mt-8 md:mt-12"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.8 }}
                >
                    <motion.div 
                        className="p-6 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10 mx-4 sm:mx-0"
                        whileHover={{ scale: 1.02 }}
                    >
                        <motion.h3 
                            className="text-lg sm:text-xl font-semibold mb-3 text-white"
                            whileHover={{ scale: 1.05 }}
                        >
                            Don't see a suitable position?
                        </motion.h3>
                        <p className="text-gray-300 text-sm sm:text-base mb-4">
                            We're always interested in connecting with talented individuals. If you believe you'd be a great fit for our team, 
                            don't wait for an opening - send your CV and cover letter to:
                        </p>
                        <motion.a 
                            href="mailto:careers@yourcompany.com" 
                            className="inline-flex items-center gap-2 text-white hover:text-gray-200 transition-colors duration-300 font-medium"
                            whileHover={{ scale: 1.1 }}
                        >
                            support@jaycolinks.com
                            <Send size={16} />
                        </motion.a>
                    </motion.div>
                </motion.div>
            </motion.div>
        </motion.div>
    );
};

export default Career;