import React, { useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import Team from "./pages/Team";

const App = () => {
  const [currentPage, setCurrentPage] = useState("Home");

  const renderPage = () => {
    switch (currentPage) {
      case "Home":
        return <Home setCurrentPage={setCurrentPage} />;
      case "About":
        return <About setCurrentPage={setCurrentPage} />;
      case "Portfolio":
        return <Portfolio setCurrentPage={setCurrentPage} />;
      case "Career":
        return <Career setCurrentPage={setCurrentPage} />;
      case "Contact":
        return <Contact setCurrentPage={setCurrentPage} />;
      case "Team":
        return <Team setCurrentPage={setCurrentPage} />;
      default:
        return <Home setCurrentPage={setCurrentPage} />;
    }
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Navbar */}
      <Navbar setCurrentPage={setCurrentPage} />

      {/* Main Content */}
      <div className="flex-grow">{renderPage()}</div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default App;